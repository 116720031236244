table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;
  }
  
  th, td {
    text-align: left;
    font-size:.9vw;
    padding: 6px;
  }
  
  tr:nth-child(even) {
    /* background-color: #f2f2f2; 
    background-color: #1f7c41;*/
    background-color: #d5f4e6;
  
  }

  body {
    font-family: sans-serif;
  }