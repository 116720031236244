input {
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 1px solid black;
    height: 24px;
    padding: 5px 5px;
    /*font-size:small;*/
    font-size:small;
    white-space: nowrap;
  }
.flex-container {
  display: flex;
  flex-wrap: nowrap;
  background-color: DodgerBlue;
}

.flex-container > div {
  background-color: #f1f1f1;
  width: 100px;
  margin: 10px;
  text-align: center;
  line-height: 75px;
  font-size: 30px;
}

.h6 {
  justify-content: center;
  align-items: center;
}

.scrollable-div {
  background-color: #f1f1f1;
  height: 300px;
  overflow: auto;
  margin: 10px;
  text-align: justify;
  padding: 10px;
}
